import styled from "styled-components";

export const ScMapWrapper = styled.div`
  min-height: 720px;
  #storepoint-container #storepoint-search {
    border: none;
    border-radius: 10px 10px 0 0;
    padding: 15px 10px;
    background: ${({ theme }) => theme.colors.darkText} !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    @media (min-width: 768px) {
      width: 100%;
      gap: 40px;
    }
    @media (min-width: 1220px) {
      border-radius: 30px 30px 0 0;
      padding: 17px 15px;
      width: 35%;
      margin-right: 20px;
      height: 70px;
    }
    #storepoint-radius-dropdown {
      margin: 0 !important;
      height: 46px;
      display: flex;
      align-items: center;
    }
  }
  .storepoint-name,
  .storepoint-address {
    color: ${({ theme }) => theme.colors.strongWhite};
    margin: 0 !important;
    padding: 0 !important;
    font: ${({ theme }) => theme.fonts.map.smallTitle} !important;
    @media (min-width: 1220px) {
      font: ${({ theme }) => theme.fonts.map.title} !important;
    }
    @media (max-width: 1219px) {
      max-width: 60%;
    }
  }
  .storepoint-address {
    font: ${({ theme }) => theme.fonts.map.smallText} !important;
    @media (min-width: 1220px) {
      font: ${({ theme }) => theme.fonts.map.text} !important;
    }
  }
  .storepoint-contact {
    font: ${({ theme }) => theme.fonts.map.smallTitle} !important;
    margin: 12px 0 0 0 !important;
    padding: 0 !important;
    @media (min-width: 1220px) {
      font: ${({ theme }) => theme.fonts.map.title} !important;
    }
  }
  #storepoint-container
    .storepoint-location
    .storepoint-contact
    a.storepoint-sidebar-phone {
    color: ${({ theme }) => theme.colors.peach} !important;
    margin: 0 !important;
    padding: 0 !important;
    font: ${({ theme }) => theme.fonts.map.smallText} !important;
    @media (min-width: 1220px) {
      font: ${({ theme }) => theme.fonts.map.text} !important;
    }
  }
  #storepoint-container .storepoint-location {
    background: ${({ theme }) => theme.colors.darkText} !important;
    padding: 20px 0;
    box-sizing: border-box;
    margin: 0 10px;
    position: relative;
    border: none;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.wheat}`};
    @media (min-width: 1220px) {
      padding: 40px 0;
      margin: 0 20px;
    }
  }
  .storepoint-btn {
    position: absolute;
    top: 20px;
    right: 0;
    width: fit-content;
    display: contents;
    @media (min-width: 1220px) {
      top: 40px;
    }
  }
  #storepoint-container #storepoint-panel a.storepoint-btn {
    width: 75px;
    height: 35px;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkText} !important;
    background: ${({ theme }) => theme.colors.peach} !important;
    font: ${({ theme }) => theme.fonts.button.small} !important;
    border-radius: 20px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 1220px) {
      font: ${({ theme }) => theme.fonts.button.large} !important;
      width: 107px;
      height: 40px;
    }
  }
  #storepoint-container #storepoint-panel {
    background: ${({ theme }) => theme.colors.transparent};
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    padding: 0 !important;
    background: ${({ theme }) => theme.colors.darkText};
    margin-bottom: 8px;
    width: 100%;
    @media (min-width: 1220px) {
      width: 35%;
      height: 650px;
      border-radius: 0 0 30px 30px;
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
  #storepoint-results {
    margin-left: 14px;
    margin-bottom: 25px;
    direction: rtl;
  }
  #storepoint-results > * {
    direction: ltr;
  }
  #storepoint-results::-webkit-scrollbar {
    width: 11px;
  }

  #storepoint-results::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.strongWhite};
    border-radius: 30px;
    height: 300px;
  }

  #storepoint-results::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.peach};
    cursor: pointer;
    border-radius: 30px;
  }

  #storepoint-container #storepoint-map {
    border-radius: 10px;
    border: ${({ theme }) => `0.5px solid ${theme.colors.gray}`};
    position: relative !important;
    width: 100% !important;
    @media (min-width: 1220px) {
      border-radius: 30px;
      width: calc(65% - 20px) !important;
      border: ${({ theme }) => `1px solid ${theme.colors.gray}`};
      height: 720px !important;
      margin-top: -70px;
    }
    @media (max-width: 767px) {
      position: relative !important;
    }
  }
  #storepoint-brand {
    display: none !important;
  }
  #storepoint-container #storepoint-panel #storepoint-results {
    padding: 0;
    @media (min-width: 601px) {
      height: 455px;
    }
    @media (min-width: 1220px) {
      height: 625px;
    }
  }
  #stpt-show-more-btn {
    width: 100px;
    height: 40px;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkText} !important;
    background: ${({ theme }) => theme.colors.peach} !important;
    font: ${({ theme }) => theme.fonts.map.smallTitle} !important;
    border-radius: 20px;
    padding: 0;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #storepoint-container .mapboxgl-ctrl-geocoder,
  #storepoint-container #storepoint-search .storepoint-dropdown {
    .mapboxgl-ctrl-geocoder--input {
      height: 46px !important;
      font: ${({ theme }) => theme.fonts.map.smallText} !important;
      @media (min-width: 1220px) {
        font: ${({ theme }) => theme.fonts.map.text} !important;
      }
    }
    border-radius: 54px;
    background: ${({ theme }) => theme.colors.strongWhite} !important;
    color: ${({ theme }) => theme.colors.darkText} !important;
    font: ${({ theme }) => theme.fonts.map.smallText} !important;
    @media (min-width: 1220px) {
      font: ${({ theme }) => theme.fonts.map.text} !important;
    }
  }
  #storepoint-search-bar-container {
    @media (min-width: 1220px) {
      width: 100% !important;
    }
  }
  #storepoint-radius-dropdown {
    width: 100px !important;
    @media (min-width: 1220px) {
      min-width: 110px;
      width: auto !important;
    }
  }
  svg.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
    top: 13px;
  }
  .mapboxgl-ctrl-geocoder {
    min-width: fit-content;
  }
`;
