import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScContainer } from '../container/styled';

import StoreFinder from './StoreFinder';
import { ScMapWrapper } from './styled';

export const Map = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<MotionDiv variants={fadeInUp}>
					<ScMapWrapper>
						<StoreFinder />
					</ScMapWrapper>
				</MotionDiv>
			</ScContainer>
		</motion.section>
	);
};
