import React from 'react';
import { useEffect } from 'react';

export default function StoreFinder() {
	useEffect(() => {
		const a = document.createElement('script');
		a.type = 'text/javascript';
		a.async = !0;
		a.src = 'https://cdn.storepoint.co/api/v1/js/163d501120d560.js';
		const b = document.getElementsByTagName('script')[0];
		b.parentNode && b.parentNode.insertBefore(a, b);
	}, []);

	return <div id={'storepoint-container'} data-map-id="163d501120d560"></div>;
}
