import React from 'react';
import { Link } from 'gatsby';

import image2 from '../assets/images/bike-logo.png';
import image3 from '../assets/images/promise-2.png';
import image1 from '../assets/images/promise-3.png';
import { CustomButton } from '../components/custom-button';
import { IImageTextCardItem } from '../components/ImageTextCard';
import { Map } from '../components/Map/Map';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { HowItWorks } from '../sections/EmplooyeeNursery/HowItWorks';
import { BikeCalculatorSection } from '../sections/EmployeeCycling/BikeCalculator';
import { EmployeeCyclingHero } from '../sections/EmployeeCycling/EmployeeCyclingHero';
import { InfoSection } from '../sections/EmployeeCycling/InfoSection';
import { InviteYourEmployer } from '../sections/EmployeeCycling/InviteYourEmployer';
import { ShopsHero } from '../sections/EmployeeCycling/ShopsHero';

const items: IImageTextCardItem[] = [
	{
		title: 'Invite</br> employer',
		text: 'It’s completely free</br> for them. Yes, really',
		image: image1,
	},
	{
		title: 'Choose your dream bike',
		text: 'Go on.</br> Get the fancy one',
		image: image2,
	},
	{
		title: 'Spend and spend again',
		text: 'New lights in the winter?</br> No problem. Our flexi-voucher puts you in charge ',
		image: image3,
	},
];

const EmployeeCycling = () => {
	return (
		<MainLayout
			title="Employee Benefits of Cycle to Work Scheme | Save on a New Bike"
			description="Discover how Gogeta’s Cycle to Work scheme helps employees save on a new bike, improve well-being, and cut commuting costs with salary sacrifice benefits."
		>
			<ScPage>
				<EmployeeCyclingHero />
				<BikeCalculatorSection isItEmployee={true} />
				<ShopsHero />
				<Map />
				<HowItWorks items={items} />
				<InfoSection
					title="FAQs"
					text="Got more questions? We’ve got you covered."
					buttons={
						<Link to="/employee-faq/">
							<CustomButton>Get some answers</CustomButton>
						</Link>
					}
				/>
				<InviteYourEmployer />
				<InfoSection
					title="There’s more where that came from"
					text="With Gogeta you can also save on nursery fees."
					buttons={
						<Link to="/employee-nursery/">
							<CustomButton>Help me save on nursery</CustomButton>
						</Link>
					}
				/>
			</ScPage>
		</MainLayout>
	);
};

export default EmployeeCycling;
